import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

export default class ExhibitionArtists extends React.Component {

    hasArtists(artists) {
        return (
            <ul className="artists columns is-multiline margin-top-0 padding-top-0">
                {console.log(artists)}
            {Object.keys(artists).map(function(key) {
                const typename = artists[key].__typename;
                if (typename === "WordPressAcf_collection_artist") {
                    console.log(artists[key]);
                    return (
                        <li className="column is-6" key={artists[key].artist_post.post_name}>
                            <Link to={ "/collection/" + artists[key].artist_post.post_name }>
                                {artists[key].artist_post.post_title}
                            </Link>
                        </li>
                    )
                }
                if (typename === "WordPressAcf_external_artist") {
                    return (
                        <li className="column is-6" key={artists[key].id}>
                            {artists[key].artist_name}
                        </li>
                    )
                }
                })}
            </ul>
        )
    }
    render() {
        const { artists } = this.props
        return (
            artists ? this.hasArtists(artists) : false
        )
    }
}

ExhibitionArtists.propTypes = {
    artists: PropTypes.arrayOf(PropTypes.object)
}