import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Swipeable } from 'react-swipeable'

import { Transition, animated } from 'react-spring/renderprops'

function createSlide(slides, index) {
    console.log(slides)
    return (
        <div className="slide">
            <Img style={{ maxHeight: '80vh', width: '100%'}} fluid={slides[index].localFile.childImageSharp.fluid} key={slides[index].id}/>
            {slides[index].caption ? <p dangerouslySetInnerHTML={{__html: slides[index].caption}}/> : false}
            {slides[index].alt_text ? <p className="caption" style={{fontStyle: 'italic'}} dangerouslySetInnerHTML={{__html: slides[index].alt_text}}/> : false}
            {slides[index].acf ? <p dangerouslySetInnerHTML={{__html: slides[index].acf.copyright}}/> : false}
            <p className="slider__swipeable-inline">Swipe to see more</p>
        </div>
    )
}

export default class Slider extends React.Component {
  state = {
    index: 0,
    comingFrom: { opacity: 0, position: "absolute", transform: "translate3d(100%, 0 ,0)" },
    leavingTo: { opacity: 0, position: "absolute", transform: "translate3d(-50%, 0, 0)" }
  };
  nextSlide = e =>
    this.setState(state => ({
      index: state.index === this.props.slides.length - 1 ? 0 : state.index + 1,
      comingFrom: { opacity: 0, position: "absolute", transform: "translate3d(100%, 0 ,0)" },
      leavingTo: { opacity: 0, position: "absolute", transform: "translate3d(-50%, 0, 0)" }
    }));
  prevSlide = e =>
    this.setState(state => ({
      index: state.index === 0 ? this.props.slides.length - 1 : state.index - 1,
      comingFrom: { opacity: 0, position: "absolute", transform: "translate3d(-50%, 0 ,0)" },
      leavingTo: { opacity: 0, position: "absolute", transform: "translate3d(100%, 0, 0)" }
    })
  );
  render() {
    const { index, comingFrom, leavingTo } = this.state;
    return (
        <div className="slider">
          <div className="columns is-2 slider__nav next" onClick={this.nextSlide}>Next</div>
          <div className="columns is-2 slider__nav prev" onClick={this.prevSlide}>Previous</div>
          <Swipeable
            className="slider"
            trackMouse
            preventDefaultTouchmoveEvent
            onSwipedLeft={this.nextSlide}
            onSwipedRight={this.prevSlide}
          >
            <Transition
              native
              reset
              unique
              items={index}
              from={comingFrom}
              enter={{ opacity: 1, position: "relative", transform: "translate3d(0%, 0, 0)" }}
              leave={leavingTo}
            >
              {index => style => (
                <animated.div className="animated" style={{ ...style }}>
                  { createSlide(this.props.slides, index) }
                </animated.div>
              )}
            </Transition>
          </Swipeable>
        </div>
    );
  }
}

Slider.propTypes = {
    slides: PropTypes.arrayOf(PropTypes.object),
}