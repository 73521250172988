import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Reveal from 'react-reveal/Reveal';

import SectionHead from '../components/SectionHead'
import ExhibitionDates from '../components/ExhibitionDates'
import ExhibitionArtistsCombined from '../components/ExhibitionArtistsCombined'

import ContentBlocks from '../components/ContentBlocks'

export const ExhibitionTemplate = ({
  title,
  children
}) => {
  return (
    <section className="exhibiton">
      <SectionHead title={title} />
      <div className="container content">
       {children}
      </div>
    </section>
  )
}

ExhibitionTemplate.propTypes = {
  title: PropTypes.string,
}

const Exhibition = ({ data }) => {
  const { wordpressWpExhibitions: post } = data

  return (
    <div>
      <Helmet title={`${post.title} | Q Contemporary`} />
      <ExhibitionTemplate
        title={post.title}
      >
      <Reveal effect="fadeInUpSlow">
        <div className="exhibition__meta is-multiline columns">
          <div className="column is-4 is-12-mobile">
            {post.acf.start_date ? 
              <ExhibitionDates start={post.acf.start_date} end={post.acf.end_date} location={post.acf.location} />
            : false }
          </div>
          {post.acf.artists ? 
            <div className="column is-2">Artists:</div> : 
          false }
          <div className="column is-6">
            { post.acf.artists ? 
              post.acf.artists :
            false }
          </div>
        </div>

          { post.acf.content_blocks_exhibition
            ?  <ContentBlocks content={post.acf.content_blocks_exhibition} />
            : false
          }
        </Reveal>

      </ExhibitionTemplate>
    </div>
  )
}

Exhibition.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default Exhibition

export const pageQuery = graphql`
  fragment ExhibitionFields on wordpress__wp_exhibitions {
    id
    slug
    content
    date(formatString: "MMMM DD, YYYY")
    title
  }
  query ExhibitionByID($id: String!) {
    wordpressWpExhibitions(id: { eq: $id }) {
      id
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      acf {
        location
        start_date(formatString: "MMMM DD, YYYY")
        end_date(formatString: "MMMM DD, YYYY")
        artists
        content_blocks_exhibition {
          ... on WordPressAcf_text_block {
            __typename
            width
            text
          }
          ... on WordPressAcf_image_block {
            __typename
            width
            image {
              caption
              acf {
                copyright
              }
              id
              localFile {
                childImageSharp {
                    # Try editing the "maxWidth" value to generate resized images.
                    fluid(maxWidth: 1680) {
                      ...GatsbyImageSharpFluid
                    }
                }
           	  }
            }
          }
          ... on WordPressAcf_gallery_block {
            __typename
            width
            gallery {
              caption
              alt_text
              id
              acf {
                copyright
              }
              localFile {
                id
                childImageSharp {
                  # Try editing the "maxWidth" value to generate resized images.
                  fluid(maxWidth: 1680) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
