import React from 'react'
import PropTypes from 'prop-types'
import Reveal from 'react-reveal/Reveal';

export default class ExhibitionDates extends React.Component {
    formatDate(startDateInit, endDateInit, location) {
        var optionsStart = { month: 'long', day: 'numeric' };
        var optionsEnd = { year: 'numeric', month: 'long', day: 'numeric' };
        var startDate = new Date(startDateInit);
        var endDate = new Date(endDateInit);
        return (
            <Reveal effect="fadeInSlow">
                <p className="exhibition__dates padding-top-0">
                    <strong>{location}</strong><br/>{ startDate.toLocaleDateString("en-US", optionsStart) } { endDateInit ? ' – ' + endDate.toLocaleDateString("en-US", optionsEnd) : false }
                </p>
            </Reveal>
        )
    }
    render() {
        const { start, end, location } = this.props
        return(
            this.formatDate(start, end, location)
        )
    }
}

ExhibitionDates.propTypes = {
    start: PropTypes.string,
    end: PropTypes.string,
    location: PropTypes.string,
}